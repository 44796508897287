import React, { InputHTMLAttributes, useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'

import Tooltip from 'components/ui/Tooltip/Tooltip'
import { IconComponent } from '@/components/ui/Icon/IconBase'
import IconMinus from '../Icon/IconMinus'
import IconPlus from '../Icon/IconPlus'

type Props = {
  Icon?: IconComponent
  tooltipMax?: string
  tooltipMin?: string
  cy?: string
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'type'>

const FormInputNumber: React.FC<Props> = React.forwardRef<HTMLInputElement, Partial<Props>>(
  (
    { Icon = undefined, tooltipMax = '', tooltipMin = '', cy = undefined, ...props },
    forwardedRef
  ) => {
    const ref = useRef<HTMLInputElement | null>(null)

    const step = (direction: 'up' | 'down') => {
      const currentValue = Number(ref?.current?.value)
      if (Number.isNaN(currentValue) || currentValue === 0) {
        if (ref?.current) {
          ref.current.value = '0'
        }
      }

      try {
        if (direction === 'up') {
          ref?.current?.stepUp()
        } else {
          ref?.current?.stepDown()
        }
        // eslint-disable-next-line no-empty
      } catch {}

      // stepUp/stepDown do not seem to trigger the onChange event, so I'm triggering it manually
      ref?.current?.dispatchEvent(new Event('input', { bubbles: true }))
    }

    const [valueNumber, setValueNumber] = useState(props.value || 0)

    useEffect(() => {
      setValueNumber(Number(props.value) || 0)
    }, [props.value])

    // This to make it work with packages that updates the ref.current.value
    useEffect(() => {
      setValueNumber(Number(ref.current?.value))
    }, [])

    const minReached = useMemo(() => {
      if (typeof props.min !== 'number') {
        return false
      }

      return Number(valueNumber) <= props.min
    }, [valueNumber, props.min])

    const maxReached = useMemo(() => {
      if (typeof props.max !== 'number') {
        return false
      }

      return Number(valueNumber) >= props.max
    }, [valueNumber, props.max])

    return (
      <div className="block flex h-10 w-full w-full rounded-lg border border-gray-300 pb-px pl-3 pt-0 text-sm leading-none text-gray-800 placeholder-gray-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-100">
        {Icon && (
          <div className="pointer-events-none mr-3 flex h-10 w-6 items-center justify-center text-gray-500">
            <Icon size="24" />
          </div>
        )}
        <input
          type="number"
          ref={(r) => {
            ref.current = r
            if (typeof forwardedRef === 'function') {
              forwardedRef?.(r)
            }
          }}
          {...props}
          onInput={(e) => {
            props.onInput?.(e)
            setValueNumber(Number(ref.current?.value) || 0)
          }}
          className="mr-2 min-w-0 flex-grow focus:outline-none"
          data-cy={cy ? `${cy}-input` : undefined}
        />
        <div className="mx-3 ml-auto flex h-full items-center">
          <Tooltip place="top" content={minReached ? tooltipMin : ''}>
            <button
              type="button"
              onClick={() => step('down')}
              disabled={minReached}
              className={clsx(
                minReached && 'opacity-50',
                'focus-element mr-2 flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-gray-100 text-gray-500'
              )}
              data-cy={cy ? `${cy}-down` : undefined}
            >
              <IconMinus className="w-[14px]" />
            </button>
          </Tooltip>

          <Tooltip place="top" content={maxReached ? tooltipMax : ''}>
            <button
              type="button"
              onClick={() => step('up')}
              disabled={maxReached}
              className={clsx(
                maxReached && 'opacity-50',
                'focus-element flex h-7 w-7 cursor-pointer items-center justify-center rounded-full bg-gray-100 text-gray-500'
              )}
              data-cy={cy ? `${cy}-up` : undefined}
            >
              <IconPlus className="w-[14px]" />
            </button>
          </Tooltip>
        </div>
      </div>
    )
  }
)

FormInputNumber.displayName = 'FormInputNumber'

export default FormInputNumber
